import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Babylon() {
  return (
    <Layout>
      <Helmet title="Babylon | David Gray" />

      <h1>Babylon</h1>

      <h2>David Gray</h2>

      <h4>Key: Am</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Friday night I'm going nowhere</p>
          <p>All the lights are changing <span style={{ color: 'darkgreen' }}>green</span> to <span style={{ color: 'crimson' }}>red</span></p>
          <p>Turning over TV stations</p>
          <p>Situations running through my head</p>
        </Verse>
        <Verse>
          <p>Looking back through time</p>
          <p>You know it's clear that I've been blind</p>
          <p>I've been a fool</p>
          <p>To open up my heart</p>
          <p>To all that jealousy, that bitterness, that ridicule</p>
        </Verse>
        <Verse>
          <p>Saturday I'm running wild</p>
          <p>And all the lights are changing <span style={{ color: 'crimson' }}>red</span> to <span style={{ color: 'darkgreen' }}>green</span></p>
          <p>Moving through the crowds I'm pushing</p>
          <p>Chemicals are rushing in my bloodstream</p>
        </Verse>
        <Verse>
          <p>Only wish that you were here</p>
          <p>You know I'm seeing it so clear</p>
          <p>I've been afraid</p>
          <p>To show you how I really feel</p>
          <p>Admit to some of those bad mistakes I've made</p>
        </Verse>
        <Chorus>
          <p>If you want it</p>
          <p>Come and get it</p>
          <p>For crying out loud</p>
          <p>The love that I was</p>
          <p>Giving you was</p>
          <p>Never in doubt</p>
          <p>Let go your heart</p>
          <p>Let go your head</p>
          <p>And feel it now</p>
          <p>Let go your heart</p>
          <p>Let go your head</p>
          <p>And feel it now</p>
          <p>Babylon</p>
        </Chorus>
        <Verse>
          <p>Sunday all the lights of London</p>
          <p>Shining, sky is fading <span style={{ color: 'crimson' }}>red</span> to <span style={{ color: 'navy' }}>blue</span></p>
          <p>Kicking through the Autumn leaves</p>
          <p>And wondering where it is you might be going to</p>
          <p>Turning back for home</p>
          <p>You know I'm feeling so alone</p>
          <p>I can't believe</p>
          <p>Climbing on the stair</p>
          <p>I turn around to see you smiling there</p>
          <p>In front of me</p>
        </Verse>
        <Chorus>
          <p>If you want it</p>
          <p>Come and get it</p>
          <p>For crying out loud</p>
          <p>The love that I was</p>
          <p>Giving you was</p>
          <p>Never in doubt</p>
          <p>Let go your heart</p>
          <p>Let go your head</p>
          <p>And feel it now</p>
          <p>Let go your heart</p>
          <p>Let go your head</p>
          <p>And feel it now</p>
          <p>Let go your heart</p>
          <p>Let go your head</p>
          <p>And feel it now</p>
          <p>Babylon, Babylon, Babylon</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
